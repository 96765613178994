.App {
  text-align: center;
}
.logo_1{
  padding-top: 100px;  
}

.app_video {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.imgC {
  width: 70px;
  height: 30px;
  margin-left: 2px;
  margin-right: 2px;
}

.iframe {
  width: 1280px;
  height: 720px;
}

@media screen and (max-width: 1500px) {
  .iframe {
    width: 960px;
    height: 540px;
  }
}

@media screen and (max-width: 800px) {
  .iframe {
    width: 640px;
    height: 360px;
  }
}
